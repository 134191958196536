<template>
  <div class="reply">
    <div class="reply-left">
      <div
        :class="['reply-left-item', activeIndex == index ? 'acitve-item' : '']"
        v-for="(item, index) in answerTree"
        :key="index"
        @click="onClick(index)"
      >
        {{ item.groupName }}
      </div>
      <div class="reply-left-item" @click="showGroup = true">添加分组</div>
    </div>
    <div class="reply-right">
      <div class="reply-right-item" v-for="(item1,index1) in answerTree[activeIndex].fastAnswerItemDtoList" :key="index1">
        <div class="reply-right-item-label" @click="handleShowReply(item1)">
          {{ item1.answer }}
        </div>
        <i class="el-icon-delete" @click="deleteClild(item1.id)"></i>
      </div>
      <div class="reply-right-item" @click="handleShowReply2">添加回复</div>
    </div>
    <el-drawer
      size="60%"
      :visible.sync="showGroup"
      direction="btt"
      :show-close="false"
      :before-close="handleClose"
      :withHeader="false"
    >
      <div class="drawer-header">
        <div class="drawer-header-left" style="opacity: 0;">关闭</div>
        <div class="drawer-header-center">编辑分组</div>
        <div class="drawer-header-right" @click="handleClose">关闭</div>
      </div>
      <div class="group">
        <div
          class="group-item"
          v-for="(item, index) in answerTree"
          :key="index"
        >
          <el-input v-model="item.groupName" @change="groupChange(item)" />
          <i class="el-icon-delete" @click="deletefather(item.id)"></i>
        </div>
        <div class="group-item">
          <el-input
            v-model="groupName"
            placeholder="添加分组"
            @change="addGroup()"
          />
        </div>
      </div>
    </el-drawer>

    <el-drawer
      size="60%"
      :visible.sync="showReply"
      direction="btt"
      :show-close="false"
      :before-close="handleReplyClose"
      :withHeader="false"
    >
      <div class="drawer-header">
        <div class="drawer-header-left" @click="handleReplyClose">关闭</div>
        <div class="drawer-header-center">编辑回复</div>
        <div class="drawer-header-right" @click="addReply">保存</div>
      </div>
      <div class="group">
        <el-input
          v-model="activeReply.answer"
          @change="groupChange(item)"
          placeholder="请输入"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import request from "@/utils/request";
import { setRemBaseFontSize } from "@/utils/common";
import md5 from "blueimp-md5";
export default {
  data() {
    return {
      serviceCode: "",
      actionCode: "",
      activeIndex: 0,
      answerTree: [],
      showGroup: false,
      groupName: "",
      app: "",
      showReply: false,
      activeReply:{}
    };
  },
  created() {
    document.title = '快捷回复'
    this.setRemBaseFontSize();
    // 监听窗口大小变化事件，以更新基准字体大小
    window.addEventListener("resize", this.setRemBaseFontSize);
    this.serviceCode = this.$route.query.serviceCode;
    this.actionCode = this.$route.query.actionCode;
    this.app = this.$route.query.app;
    this.getAnswerTree();
  },
  methods: {
    setRemBaseFontSize,
    handleShowReply(reply) {
      this.showReply = true
      this.activeReply = JSON.parse(JSON.stringify(reply))
    },
    handleShowReply2() {
      this.showReply = true
      this.activeReply = {
        groupId: this.answerTree[this.activeIndex].id,
        answer:''
      }
      console.log();
    },
    addReply() {
      const time = new Date();
      const item = this.activeReply
      const form = {
        id: item.id ? item.id : "",
        groupId: item.groupId,
        answer: item.answer,
        app: this.app,
        serviceCode: this.serviceCode,
        actionTime: time,
        sign: md5(time.getTime() + this.actionCode),
      };
      request.post("/api/answer/setAnswerItem", form).then((res) => {
        this.getAnswerTree();
        this.showReply = false
      });
    },
    deleteClild(id) {
      const time = new Date();
      const form = {
        id,
        app: this.app,
        serviceCode: this.serviceCode,
        actionTime: time,
        sign: md5(time.getTime() + this.actionCode),
      };
      request.post(`/api/answer/deleteAnswerItem`, form).then((res) => {
        this.getAnswerTree();
      });
    },
    onClick(index) {
      this.activeIndex = index;
    },
    handleClose() {
      this.showGroup = false;
    },
    handleReplyClose() {
      this.showReply = false;
      this.activeReply = {}
    },
    addGroup() {
      const time = new Date();
      const form = {
        id: "",
        serviceCode: this.serviceCode,
        groupName: this.groupName,
        app: this.app,
        actionTime: time,
        sign: md5(time.getTime() + this.actionCode),
      };
      request.post("/api/answer/setAnswerGroup", form).then((res) => {
        this.getAnswerTree();
        this.groupName = "";
      });
    },
    groupChange(item) {
      const time = new Date();
      const form = {
        id: item.id ? item.id : "",
        serviceCode: this.serviceCode,
        groupName: item.groupName,
        app: this.app,
        actionTime: time,
        sign: md5(time.getTime() + this.actionCode),
      };
      request.post("/api/answer/setAnswerGroup", form).then((res) => {
        this.getAnswerTree();
      });
    },
    getAnswerTree() {
      request
        .get(
          `/api/answer/getAnswerTree?app=${this.app}&serviceCode=${this.serviceCode}`
        )
        .then((res) => {
          this.answerTree = res;
        });
    },
    deletefather(id) {
      const time = new Date();
      const form = {
        id,
        app: this.app,
        serviceCode: this.serviceCode,
        actionTime: time,
        sign: md5(time.getTime() + this.actionCode),
      };
      console.log(form);
      request.post(`/api/answer/deleteAnswerGroup`, form).then((res) => {
        this.getAnswerTree();
      });
    },
    deleteClild(id) {
      const time = new Date();
      const form = {
        id,
        app: this.app,
        serviceCode: this.serviceCode,
        actionTime: time,
        sign: md5(time.getTime() + this.actionCode),
      };
      request.post(`/api/answer/deleteAnswerItem`, form).then((res) => {
        this.getAnswerTree();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reply {
  font-size: 0.16rem;
  display: flex;
  height: 100vh;
  &-left {
    background-color: #f2f2f2;
    height: 100%;
    overflow-y: auto;
    &-item {
      padding: 0.1rem 0.2rem;
    }
  }
  .acitve-item {
    background-color: #fff;
  }
  &-right {
    overflow-y: auto;
    flex: 1;
    width: 0;
    box-sizing: border-box;
    &-item {
      padding: 0.1rem 0.2rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dcdfe6;
      &-label {
        flex: 1;
        margin-right: 0.1rem;
        overflow: hidden; 
        text-overflow: ellipsis; 
        white-space: nowrap;
      }
    }
  }
}
.drawer-header {
  display: flex;
  padding: 0.2rem;
  height: 0.7rem;
  box-sizing: border-box;
  align-items: center;
  &-left {
  }
  &-center {
    flex: 1;
    text-align: center;
    font-weight: bold;
  }
  &-right {
    color: #fff;
    background-color: #07c160;
    padding: 0.08rem 0.15rem;
    border-radius: 0.05rem;
  }
}
.group {
  height: calc(100% - 0.7rem);
  overflow-y: auto;
  &-item {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dcdfe6;
  }
}
::v-deep .el-input__inner {
  border-radius: 0;
  border: 0;
}
</style>